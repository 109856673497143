import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { Button, TextField, Textarea, Combobox, ColorPicker, ToggleSwitch, MessageBox, } from '@schuettflix/react-components';
import { Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { LocationPickerInput } from '@schuettflix/react-components';
import { useMarketLanguage } from '../../hooks/useMarketLanguage';
import { useMarketCountryCode } from '../../hooks/useMarketCountryCode';
import { FormField } from '@/constructionProjects/components/FormField';
import { useZodForm } from '@/reactBridge/useZodForm';
import { useBreakpoints } from '@/constructionProjects/hooks/useBreakpoints';
import Toaster from '@/services/Toaster';
import { GeolocationService } from '@/services/Geolocation/GeolocationService';
import LocationApi from '@/services/Api/Location';
import PlatformApi from '@/services/Api/Platform/Utils';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useId, useMemo } from 'react';
import { differenceWith, uniqBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import { createPortal } from 'react-dom';
const validateDateAfter = (validFrom, validTo) => new Date(validFrom) < new Date(validTo);
const MAX_NAME_CHARACTERS = 130;
const constructionProjectFormSchema = z
    .object({
    active: z.boolean(),
    salesforceProjectId: z
        .string()
        .regex(/[\da-zA-z]{15,18}/)
        .optional(),
    name: z
        .string()
        .min(1, 'pages.constructionProject.createForm.nameField.messages.required')
        .max(MAX_NAME_CHARACTERS, 'pages.constructionProject.createForm.nameField.messages.maxLengthDynamic'),
    description: z.string().min(0).max(144, 'pages.constructionProject.createForm.descriptionField.error'),
    location: z.object({
        address: z.object({
            formatted: z.string().nullable(),
            country: z.string().nullable(),
            state: z.string().nullable(),
            city: z.string({
                required_error: 'pages.constructionProject.createForm.locationPicker.messages.city.required',
                invalid_type_error: 'pages.constructionProject.createForm.locationPicker.messages.city.required',
            }),
            street: z.string().nullable(),
            streetNumber: z.string().nullable(),
            zipCode: z.string({
                required_error: 'pages.constructionProject.createForm.locationPicker.messages.zip.required',
                invalid_type_error: 'pages.constructionProject.createForm.locationPicker.messages.zip.required',
            }),
            placeId: z.string().nullable(),
        }),
        coordinates: z.object({
            latitude: z.number().min(-90).max(90),
            longitude: z.number().min(-180).max(180),
        }),
    }),
    responsibleUserId: z.number(),
    costCenter: z.string().optional(),
    validFrom: z.string().min(1, 'pages.constructionProject.createForm.datePicker.messages.validFrom'),
    validTo: z.string().min(1, 'pages.constructionProject.createForm.datePicker.messages.validToRequired'),
    teamMembers: z.number().array(),
    orderConfirmationMails: z
        .object({
        email: z.string().email(),
        userId: z.number().optional(),
        type: z.enum(['EMAIL', 'USER', 'ORGANIZATION']),
    })
        .array()
        .nonempty('pages.constructionProject.createForm.email.errorMessage.required'),
    color: z
        .string()
        .regex(/^#([0-9a-f]{3}){1,2}$/i)
        .nullable(),
})
    .refine(data => validateDateAfter(data.validFrom, data.validTo), {
    path: ['validTo'],
    message: 'pages.constructionProject.createForm.datePicker.messages.validTo',
});
export const ConstructionProjectForm = ({ form, organizationUsers, organizationEmail, submissionLoading, onCancelClick, onSubmit, submitEnabled = true, submitButtonLabel, cancelButtonLabel, costCenterMandatory, buttonsPortal, }) => {
    const { control, register, formState: { errors, isValid }, getValues, setValue, handleSubmit, } = form;
    const { t, i18n } = useTranslation();
    const country = useMarketCountryCode();
    const language = useMarketLanguage();
    const { isGreaterOrEqual } = useBreakpoints();
    const queryClient = useQueryClient();
    useEffect(() => {
        form.trigger();
    }, [form]);
    const confirmationEmailOptions = useMemo(() => {
        const mapUserToOptions = (user) => {
            return {
                label: `${user.firstName} ${user.lastName}`,
                description: user.email || '',
                value: {
                    userId: user.id,
                    email: user.email || '',
                    type: 'USER',
                },
            };
        };
        const mapOrganizationEmail = (email) => {
            return email
                ? [
                    {
                        label: t('pages.constructionProject.createForm.email.orgEmail.label'),
                        description: email,
                        value: {
                            email: email,
                            type: 'ORGANIZATION',
                        },
                    },
                ]
                : [];
        };
        const mapValuesToOptions = (values) => {
            return values.map((value) => ({
                label: value.email || '',
                value: value,
            }));
        };
        return ([]
            .concat(mapOrganizationEmail(organizationEmail))
            .concat(organizationUsers?.filter((user) => user.email)?.map(mapUserToOptions) ?? [])
            .concat(mapValuesToOptions(getValues('orderConfirmationMails'))) || []);
    }, [t, organizationUsers, organizationEmail, getValues]);
    const localizeComparator = new Intl.Collator(i18n.language);
    const isProjectExpired = new Date(form.getValues('validTo')) < new Date();
    const formId = useId();
    const buttons = (<>
            {onCancelClick && (<Button size={isGreaterOrEqual('md') ? 'sm' : 'md'} type="button" variant="secondary" label={cancelButtonLabel} className="w-1/2 md:w-fit" onClick={onCancelClick}/>)}
            <Button form={formId} size={isGreaterOrEqual('md') ? 'sm' : 'md'} disabled={submissionLoading || !submitEnabled || !isValid} label={submitButtonLabel} type="submit" className="w-1/2 md:w-fit" data-test="create-form-submit-button"/>
        </>);
    return (<form id={formId} className="flex h-full flex-col justify-between" onSubmit={handleSubmit(onSubmit)}>
            <div className="container-content bg py-6">
                <FormField title={t('pages.constructionProject.createForm.nameField.title')} description={t('pages.constructionProject.createForm.nameField.description')} className="mb-12">
                    <TextField data-test="text-field" {...register('name', { required: true })} label={t('pages.constructionProject.createForm.nameField.label')} errorMessage={errors.name?.message
            ? t(errors.name?.message, { maxCharacters: MAX_NAME_CHARACTERS })
            : undefined}/>
                </FormField>

                <FormField title={t('pages.constructionProject.createForm.descriptionField.title')} description={t('pages.constructionProject.createForm.descriptionField.description')}>
                    <Textarea {...register('description')} placeholder={t('pages.constructionProject.createForm.descriptionField.placeholder')}/>
                    <p className="font-copy-sm mb-12 mt-2 text-critical">
                        {errors.description?.message ? t(errors.description?.message) : undefined}
                    </p>
                </FormField>

                <FormField title={t('pages.constructionProject.createForm.addressField.title')} description={t('pages.constructionProject.createForm.addressField.description')} className="mb-12">
                    <Controller name="location" control={control} render={({ field }) => (<LocationPickerInput country={country} language={language} defaultMapType={google.maps.MapTypeId.SATELLITE} label={t('pages.constructionProject.createForm.addressField.label')} title={t('pages.constructionProject.createForm.addressField.locationPicker.title')} translationMap={{
                addressFieldLabel: t('pages.constructionProject.createForm.addressField.label'),
                getCurrentPositionError: t('components.locationPicker.getCurrentPosition.error'),
                locationCodeLabel: t('components.locationPicker.locationCode.label'),
                mapTypeHeadline: t('components.locationPicker.mapType.headline'),
                mapTypeStandard: t('components.locationPicker.mapType.standard'),
                mapTypeSatellite: t('components.locationPicker.mapType.satellite'),
                setButtonLabel: t('pages.constructionProject.createForm.addressField.locationPicker.setButton.label'),
            }} triggerToasterError={Toaster.error.bind(Toaster)} getCurrentLocation={GeolocationService.getCurrentLocation.bind(GeolocationService)} getOpenLocationCode={LocationApi.getOpenLocationCode.bind(LocationApi)} getPlatformInfo={PlatformApi.getPlatformInfo.bind(PlatformApi)} errorMessage={(errors.location?.message
                ? t('pages.constructionProject.createForm.locationPicker.messages.required')
                : undefined) ??
                (errors.location?.address?.city?.message
                    ? t(errors.location?.address?.city?.message)
                    : undefined) ??
                (errors.location?.address?.zipCode?.message
                    ? t(errors.location?.address?.zipCode?.message)
                    : undefined)} queryClient={queryClient} {...field}/>)}/>
                </FormField>

                <FormField title={costCenterMandatory
            ? t('pages.constructionProject.createForm.costField.mandatoryTitle')
            : t('pages.constructionProject.createForm.costField.title')} description={costCenterMandatory
            ? t('pages.constructionProject.createForm.costField.mandatoryDescription')
            : t('pages.constructionProject.createForm.costField.description')}>
                    <TextField {...register('costCenter')} className="mb-12" data-test="costCenterField" label={t('pages.constructionProject.createForm.costField.label')} errorMessage={errors.costCenter?.message ? t(errors.costCenter?.message) : undefined}/>
                </FormField>

                <FormField title={t('pages.constructionProject.createForm.validityField.title')} description={t('pages.constructionProject.createForm.validityField.description')}>
                    <div className="flex gap-6">
                        <div className="w-1/2">
                            <TextField {...register('validFrom')} type="date" errorMessage={errors.validFrom?.message ? t(errors.validFrom?.message) : undefined} label={t('pages.constructionProject.createForm.validityField.validFrom.label')}/>
                        </div>
                        <div className="w-1/2">
                            <TextField data-test="valid-date" {...register('validTo')} type="date" errorMessage={errors.validTo?.message ? t(errors.validTo?.message) : undefined} label={t('pages.constructionProject.createForm.validityField.validTo.label')}/>
                        </div>
                    </div>
                </FormField>

                <hr className="border-1 my-12 border-solid border-divider"/>

                <FormField title={t('pages.constructionProject.createForm.personField.title')} description={t('pages.constructionProject.createForm.personField.description')}>
                    <Controller name="responsibleUserId" control={control} 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render={({ field: { ref, onChange, ...fieldProps }, fieldState }) => {
            const options = organizationUsers.map(user => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
            }));
            const handleChange = (newSelectedUser) => {
                setValue('teamMembers', getValues('teamMembers').filter(id => id !== newSelectedUser));
                onChange(newSelectedUser);
            };
            return (<Combobox className="mb-12 w-full" label={t('pages.constructionProject.createForm.personField.label')} multiple={false} options={options.sort((a, b) => localizeComparator.compare(a.label, b.label)) ?? []} onChange={handleChange} {...fieldProps} errorMessage={fieldState.error?.message}/>);
        }}/>
                </FormField>

                <FormField title={t('pages.constructionProject.createForm.teamField.title')} description={t('pages.constructionProject.createForm.teamField.description')}>
                    <Controller name="teamMembers" control={control} 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render={({ field: { ref, ...fieldProps } }) => {
            const options = organizationUsers.map(user => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
                disabled: user.id === getValues('responsibleUserId'),
            }));
            return (<Combobox className="mb-12 w-full" label={t('pages.constructionProject.createForm.teamField.label')} multiple options={options.sort((a, b) => localizeComparator.compare(a.label, b.label)) ?? []} placeholder={t('pages.constructionProject.createForm.teamField.placeholder')} {...fieldProps}/>);
        }}/>
                </FormField>

                <FormField title={t('pages.constructionProject.createForm.confirmationEmailsField.title')} description={t('pages.constructionProject.createForm.confirmationEmailsField.description')}>
                    <Controller name="orderConfirmationMails" control={control} render={({ field: { ref: _ref, value, onChange, ...fieldProps } }) => {
            const handleAddOption = (newValue) => {
                const preparedValue = {
                    type: 'EMAIL',
                    email: newValue,
                };
                const selectedValues = value || [];
                const foundInOptions = confirmationEmailOptions.find(option => option.value.email === newValue);
                const foundInValues = selectedValues.find(option => option.email === newValue);
                if (foundInValues) {
                    return;
                }
                if (foundInOptions && !foundInValues) {
                    preparedValue.userId = foundInOptions.value.userId;
                    preparedValue.type = foundInOptions.value.type;
                }
                setValue('orderConfirmationMails', [...(selectedValues || []), preparedValue]);
            };
            const handleChange = (newValue) => {
                const parsedValue = newValue.map(option => JSON.parse(option));
                const uniqueEmails = uniqBy(parsedValue, 'email');
                const duplicates = differenceWith(parsedValue, uniqueEmails, isEqual);
                if (duplicates.length > 0) {
                    const existingElement = uniqueEmails.find(item => item.email);
                    if (existingElement?.email) {
                        Toaster.warning(t('pages.constructionProject.createForm.confirmationEmailsField.emailAlreadySelected', { email: existingElement.email }));
                    }
                }
                onChange(uniqueEmails);
            };
            const uniqueOptionList = uniqBy(confirmationEmailOptions, 'value.email');
            const missingOptions = value.filter(val => !uniqueOptionList.some(option => isEqual(option.value, val)));
            const options = [
                ...uniqueOptionList,
                ...missingOptions.map(missingOption => ({
                    label: missingOption.email,
                    description: missingOption.email || '',
                    value: missingOption,
                })),
            ];
            const getErrorMessage = () => {
                if (errors.orderConfirmationMails?.message) {
                    return t(errors.orderConfirmationMails?.message);
                }
                const firstError = errors.orderConfirmationMails?.filter?.(error => error)?.[0];
                if (firstError?.message) {
                    return t(firstError?.message);
                }
                if (firstError?.email?.message) {
                    return t(firstError?.email?.message);
                }
            };
            return (<>
                                    <Combobox label={t('pages.constructionProject.createForm.confirmationEmailsField.label')} placeholder={t('pages.constructionProject.createForm.confirmationEmailsField.placeholder')} data-test="order-confirmation-emails" noOptionFoundLabel={t('pages.constructionProject.createForm.confirmationEmailsField.noOptionFound')} className="mb-12 w-full" multiple options={options.map(option => ({
                    ...option,
                    value: JSON.stringify(option.value),
                })) ?? []} value={value.map(option => JSON.stringify(option))} onChange={handleChange} allowOptionAdding onAddOption={handleAddOption} errorMessage={getErrorMessage()} {...fieldProps}/>
                                </>);
        }}/>
                </FormField>

                <FormField title={t('pages.constructionProject.createForm.colorField.title')} description={t('pages.constructionProject.createForm.colorField.description')}>
                    <Controller name="color" control={control} 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render={({ field: { ref, ...fieldProps } }) => {
            const colors = [
                '#E4C3AD',
                '#FFB767',
                '#D9DC76',
                '#D1C9B4',
                '#DFC786',
                '#D1E2CA',
                '#F5EBDC',
                '#FFE173',
            ];
            return <ColorPicker colors={colors} {...fieldProps}/>;
        }}/>
                </FormField>

                <FormField title={t('pages.constructionProject.createForm.statusField.title')} className="mt-12" description={t('pages.constructionProject.createForm.statusField.description')}>
                    <div className="flex justify-between">
                        <p className="font-copy-md-strong">
                            {t('pages.constructionProject.createForm.statusField.toggle.headline')}
                        </p>
                        <Controller name="active" control={control} render={({ field }) => (<ToggleSwitch checked={field.value} disabled={isProjectExpired} {...field}/>)}/>
                    </div>
                </FormField>

                {isProjectExpired && (<MessageBox className="mt-4">
                        <p>{t('pages.constructionProject.createForm.statusField.messageBox.text')}</p>
                    </MessageBox>)}
            </div>

            {buttonsPortal ? (createPortal(buttons, buttonsPortal)) : (<div className="container-content flex flex-row gap-4 bg-surface py-4 md:justify-end">{buttons}</div>)}
            <DevTool control={control}/>
        </form>);
};
export const useConstructionProjectForm = ({ defaultValues, costCenterMandatory, }) => {
    const form = useZodForm({
        defaultValues,
        mode: 'onChange',
        schema: constructionProjectFormSchema.refine(data => (costCenterMandatory ? !!data.costCenter : true), {
            path: ['costCenter'],
            message: 'pages.constructionProject.createForm.costCenter.messages.required',
        }),
    });
    return {
        form,
    };
};
