import { Button } from '@schuettflix/planum-react';
import { MessageBox } from '@schuettflix/react-components';
import { merge } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { Loader } from '../../components/Loader';
import { useNetsuitePaymentTerms } from '../../hooks/api';
import { useEdit } from '../../hooks/useEdit';
import { AccountingEdit } from './Accounting/Edit';
import { AccountingPreview } from './Accounting/Preview';
import { BaseInformationEdit } from './BaseInformation/Edit';
import { BaseInformationPreview } from './BaseInformation/Preview';
import { PlatformActions } from './components/PlatformActions';
import { SelectTypes } from './components/SelectTypes';
import { useFormOrganization } from './form';
import { useCheckExistingOrg, useIsOrgType } from './hook';
import { useDefinitionsForOrg } from './Settings/definition';
import { SettingsEdit } from './Settings/Edit';
import { SettingsPreview } from './Settings/Preview';
export function OrganizationForm(props) {
    const { t } = useTranslation();
    const form = useFormOrganization({
        defaultValues: { ...props.defaultValues, types: props.defaultValues?.types ?? [] },
    });
    const types = form.watch('types');
    const isType = useIsOrgType(types);
    const organizationTypeNeeded = !types || types.length === 0;
    const features = form.watch('features');
    const { edit, dispatch, isEditing } = useEdit({
        baseInformation: false,
        accounting: false,
        settings: false,
    });
    const disableSubmission = isEditing || !form.formState.isValid;
    const platformCodes = form.watch('platformActionCodes');
    const { getMandatoryFeaturesForType, definitions } = useDefinitionsForOrg(types);
    const check = useCheckExistingOrg(props.defaultValues?.id, props.defaultValues?.salesforceAccountId);
    // If a known id is passed, update the form with the current values
    useEffect(() => {
        if (check.org) {
            updateFromChild(check.org);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- it would cause an infinite loop
    }, [check.org]);
    useEffect(() => {
        form.trigger();
    }, [form]);
    const updateFromChild = useCallback((data) => {
        // Update data and retrigger validation
        form.reset(merge(form.getValues(), data));
        form.trigger();
    }, [form]);
    async function save() {
        if (!form.formState.isValid)
            return;
        // @ts-expect-error will fix
        props.onSubmit(form.getValues());
    }
    // DEFAULT VALUES
    const netsuitePaymentTerms = useNetsuitePaymentTerms();
    useEffect(() => {
        if (!netsuitePaymentTerms.data?.marketDefaultPaymentTermIds)
            return;
        const values = form.getValues();
        for (const type of ['client', 'supplier', 'carrier']) {
            const key = `${type}PaymentTerm`;
            if (!values[key]?.id) {
                const id = netsuitePaymentTerms.data?.marketDefaultPaymentTermIds[type];
                form.setValue(key, { id });
            }
        }
    }, [netsuitePaymentTerms.data, form]);
    useEffect(() => {
        if (form.getValues('isActive') === undefined) {
            form.setValue('isActive', true);
        }
        if (form.getValues('invoiceEmailInterval') === undefined) {
            form.setValue('invoiceEmailInterval', 'directly');
        }
        if (form.getValues('deliveryNoteEmailInterval') === undefined) {
            form.setValue('deliveryNoteEmailInterval', 'directly');
        }
        if (form.getValues('paymentInfo.method') === undefined) {
            form.setValue('paymentInfo.method', 'invoice');
        }
        if (!Array.isArray(form.getValues('invoiceRecipientEmails'))) {
            form.setValue('invoiceRecipientEmails', []);
        }
        if (!Array.isArray(form.getValues('deliveryNoteRecipientEmails'))) {
            form.setValue('deliveryNoteRecipientEmails', []);
        }
        if (form.getValues('emailDeliveryNotesAttachedToReceipt') === undefined) {
            form.setValue('emailDeliveryNotesAttachedToReceipt', true);
        }
        // Intervals
        if (isType.client && form.getValues('invoiceBillingInterval') === undefined) {
            form.setValue('invoiceBillingInterval', 'on_order_closed');
            form.setValue('invoiceBillingGroup', 'order');
        }
        if (isType.supplier && form.getValues('creditNoteSupplierBillingInterval') === undefined) {
            form.setValue('creditNoteSupplierBillingInterval', 'daily');
            form.setValue('creditNoteSupplierBillingGroup', 'project');
        }
        if (isType.carrier && form.getValues('creditNoteCarrierBillingInterval') === undefined) {
            form.setValue('creditNoteCarrierBillingInterval', 'daily');
            form.setValue('creditNoteCarrierBillingGroup', 'project');
        }
    }, [form, isType]);
    const fillFeatures = useCallback(() => {
        let newFeatures = features;
        for (const type of types) {
            newFeatures = {
                ...newFeatures,
                ...getMandatoryFeaturesForType(type),
            };
        }
        form.setValue('features', newFeatures);
    }, [form, features, types, getMandatoryFeaturesForType]);
    const [prefilledFeatures, setPrefilledFeatures] = useState(false);
    useEffect(() => {
        if (!prefilledFeatures && definitions.isFetched) {
            fillFeatures();
            setPrefilledFeatures(true); // Prevents infinite loop
        }
    }, [fillFeatures, prefilledFeatures, definitions]);
    // RENDER
    if (check.isLoading) {
        return <Loader />;
    }
    if (check.idMismatch) {
        return (<div className="flex flex-col gap-2 py-16">
                <Card title="Mismatch">
                    <MessageBox type="error">
                        <p>The organization id and salesforceAccountId do not match. The payload is invalid.</p>
                    </MessageBox>
                </Card>
            </div>);
    }
    if (organizationTypeNeeded) {
        return (<div className="flex flex-col gap-2 py-16">
                <Card title={t('components.organization.selectType.title')}>
                    <SelectTypes onChange={types => {
                form.setValue('types', types);
                fillFeatures();
            }}/>
                </Card>
            </div>);
    }
    const buttons = (<Button type="button" onPress={() => save()} isDisabled={disableSubmission}>
            {t('pages.organization.form.actions.continue')}
        </Button>);
    return (<div className="flex flex-col gap-2 py-16">
            {/* BASE */}
            {edit.baseInformation ? (<BaseInformationEdit onSave={data => {
                dispatch({ baseInformation: false });
                updateFromChild(data);
            }} onCancel={() => dispatch({ baseInformation: false })} defaultValue={structuredClone(form.getValues())}/>) : (<BaseInformationPreview onEdit={() => dispatch({ baseInformation: true })} form={form}/>)}

            {/* ACCOUNTING */}
            {edit.accounting ? (<AccountingEdit onSave={data => {
                dispatch({ accounting: false });
                updateFromChild(data);
            }} onCancel={() => dispatch({ accounting: false })} defaultValue={structuredClone(form.getValues())}/>) : (<AccountingPreview onEdit={() => dispatch({ accounting: true })} form={form}/>)}

            {edit.settings ? (<SettingsEdit onSave={data => {
                dispatch({ settings: false });
                updateFromChild(data);
            }} onCancel={() => dispatch({ settings: false })} defaultValue={structuredClone(form.getValues())} isNew={check.isNew}/>) : (<SettingsPreview onEdit={() => dispatch({ settings: true })} form={form}/>)}

            {platformCodes && <PlatformActions organizationPlatformActionCodes={platformCodes}/>}

            {props.buttonSlot ? createPortal(buttons, props.buttonSlot) : buttons}
        </div>);
}
